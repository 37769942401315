var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-top":"30px"}},[_c('el-card',{staticClass:"box-card"},[_c('el-form',{ref:"dynamicValidateForm",attrs:{"model":_vm.dynamicValidateForm,"label-width":"250px"}},[_vm._l((_vm.dynamicValidateForm.configList),function(item,index){return _c('el-form-item',{key:item.fieldkey,attrs:{"label":item.remark,"prop":'configList.' + index + '.fieldValue',"rules":{
          required: true,
          message: '请输入',
          trigger: 'blur',
        }}},[(
            item.fieldKey != 'auto_comment' &&
            item.fieldKey != 'auto_comment_content'&&
            item.fieldKey != 'login_back_img_config'
          )?_c('el-input-number',{attrs:{"min":1,"max":10000},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.fieldKey == 'auto_comment')?_c('el-radio-group',{model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}},[_c('el-radio',{attrs:{"label":"true"}},[_vm._v("开启")]),_c('el-radio',{attrs:{"label":"false"}},[_vm._v("关闭")])],1):_vm._e(),(item.fieldKey == 'auto_comment_content')?_c('el-input',{staticStyle:{"width":"500px"},attrs:{"type":"textarea","rows":4,"placeholder":"请输入内容"},model:{value:(item.fieldValue),callback:function ($$v) {_vm.$set(item, "fieldValue", $$v)},expression:"item.fieldValue"}}):_vm._e(),(item.fieldKey == 'login_back_img_config'&&item.fieldValue.length<=1)?_c('FormImgUpload',{attrs:{"url":item.fieldValue,"limit":1,"maxSize":5,"accept":"image/jpeg,image/png,image/jpg","multiple":true},on:{"upload":_vm.upload}}):_vm._e(),(item.fieldKey == 'login_back_img_config')?_c('div',{staticClass:"tipImg"},[_vm._v("尺寸推荐:500X500，格式：jpeg,png,jpg")]):_vm._e()],1)}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('dynamicValidateForm')}}},[_vm._v("提交")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }