<template>
  <div style="margin-top: 30px">
    <el-card class="box-card">
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="250px"
      >
        <el-form-item
          v-for="(item, index) in dynamicValidateForm.configList"
          :key="item.fieldkey"
          :label="item.remark"
          :prop="'configList.' + index + '.fieldValue'"
          :rules="{
            required: true,
            message: '请输入',
            trigger: 'blur',
          }"
        >
          <el-input-number
            v-if="
              item.fieldKey != 'auto_comment' &&
              item.fieldKey != 'auto_comment_content'&&
              item.fieldKey != 'login_back_img_config'
            "
            v-model="item.fieldValue"
            :min="1"
            :max="10000"
          ></el-input-number>

          <el-radio-group
            v-model="item.fieldValue"
            v-if="item.fieldKey == 'auto_comment'"
          >
            <el-radio label="true">开启</el-radio>
            <el-radio label="false">关闭</el-radio>
          </el-radio-group>

          <el-input
            type="textarea"
            v-if="item.fieldKey == 'auto_comment_content'"
            v-model="item.fieldValue"
            style="width: 500px;"
            :rows="4"
            placeholder="请输入内容"
          >
          </el-input>

          <FormImgUpload
          
            :url="item.fieldValue" 
            v-if="item.fieldKey == 'login_back_img_config'&&item.fieldValue.length<=1"
            @upload="upload"
            :limit="1"
            :maxSize="5"
            accept="image/jpeg,image/png,image/jpg"
            :multiple="true"
          />
          <div v-if="item.fieldKey == 'login_back_img_config'" class="tipImg">尺寸推荐:500X500，格式：jpeg,png,jpg</div>
        </el-form-item>

        

        <el-form-item>
          <el-button type="primary" @click="submitForm('dynamicValidateForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
  <script>
import {
  mallConfigList,
  mallConfigUpdate,
  getMallConfigByKey,
} from "@/api/system/config";
import FormImgUpload from "@/components/formComponents/formImgUpload";
export default {
  components: {
    FormImgUpload
  },
  data() {
    return {
      dynamicValidateForm: {
        configList: [],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      mallConfigList({ pageNum: 1, pageSize: 100 }).then((res) => {
        if (res.data.records.length > 0) {
          this.dynamicValidateForm.configList = res.data.records;
          this.dynamicValidateForm.configList.map((item,index)=>{
            if(item.fieldKey=='login_back_img_config'){
              item.fieldValue = [item.fieldValue]
            }
          })
        }
      });
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.dynamicValidateForm.configList.map((item,index)=>{
            if(item.fieldKey=='login_back_img_config'){
              item.fieldValue = [...e]
            }
          })
        this.$forceUpdate()
      } else {
        this.dynamicValidateForm.configList.map((item,index)=>{
            if(item.fieldKey=='login_back_img_config'){
              item.fieldValue = [...item.fieldValue, e]
            }
          })
        this.$forceUpdate()
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dynamicValidateForm.configList.map((item,index)=>{
            if(item.fieldKey=='login_back_img_config'){
              item.fieldValue = JSON.parse(JSON.stringify(item.fieldValue)).toString()
            }
          })
          mallConfigUpdate({
            dtoList: this.dynamicValidateForm.configList,
          }).then((res) => {
            
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getList()
          });
        } else {
        }
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>
</style>
  